.top_btns[data-v-0ea16da8] {
  margin-bottom: 10px;
}
.top_btns .left_search[data-v-0ea16da8] {
  float: left;
}
.top_btns .right_btns[data-v-0ea16da8] {
  float: right;
  text-align: right;
}
.el-input[data-v-0ea16da8] {
  width: 96%;
}
.item-p[data-v-0ea16da8] {
  border-top: 1px solid #efefef;
}
.item-p[data-v-0ea16da8]:nth-child(1) {
  border-top: none;
}